exports.linkResolver = function linkResolver(doc) {
  // Route for blog posts
  // console.log('doc', doc)

  // if (doc.blog_section[0].text === 'Astuces') {
  //   return '/blog/astuces/' + doc._meta.uid
  // }

  if (doc._meta.type === 'blog_posts') {
    return '/blog/' + doc._meta.uid
  }

  // Homepage route fallback
  return '/'
}
