module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-173223462-1","head":true,"anonymize":true,"respectDNT":true,"pageTransitionDelay":1000,"defer":true,"sampleRate":5,"siteSpeedSampleRate":10},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bébé au pays du sommeil","short_name":"starter","start_url":"/","background_color":"#ff7c6b","theme_color":"#ff7c6b","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"sleeping-baby","path":"/preview","previews":true,"defaultLang":"fr-fr","accessToken":"MC5YdFRVd2hFQUFBclFQZTFr.bmPvv71b77-9an1h77-977-9Qu-_vRUiZwpNFGEP77-9Am9M77-9Qu-_ve-_vVhEIxY","pages":[{"type":"Blog_posts","match":"/blog/:uid","path":"/blog","component":"/opt/build/repo/src/templates/post.js"},{"type":"Blog_posts","match":"/blog/astuces/:uid","path":"/blog/astuces","component":"/opt/build/repo/src/templates/post.js"},{"type":"Blog_posts","match":"/blog/astuces/","path":"/blog/astuces","component":"/opt/build/repo/src/components/BlogPosts.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
